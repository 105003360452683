<template>
  <div class="layout_common scene_handle">
    <div class="main_box">
      <div class="main_box_con" style="padding-top:10px">
        <el-tabs class="tabs" v-model="activeName" style="padding-left:0">
          <el-tab-pane label="跟进中" name="follow">
            <comp :tabType="activeName" :dataAuthority="dataAuthority" :myScene="dataAuthority" v-if="activeName==='follow'" />
          </el-tab-pane>
          <el-tab-pane label="已完成" name="finish">
            <comp :tabType="activeName" :dataAuthority="dataAuthority" :myScene="dataAuthority" v-if="activeName==='finish'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import comp from './comp'
export default {
  components: {
    comp
  },
  props: {
    // 是否加数据权限  我的上门传true
    dataAuthority: {
      type: Boolean,
      default: false
    },
    // 是否是我的上门
    myScene: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeName: 'follow'
    }
  }
}
</script>
