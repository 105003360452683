<template>
  <scene :dataAuthority="true" :myScene="true" />
</template>

<script>
import scene from '@/views/work-order/scene-handle'
export default {
  components: {
    scene
  }
}
</script>
