<template>
  <el-select
    v-model="serviceName"
    placeholder="服务站模糊搜索"
    filterable
    size="small"
    reserve-keyword
    :remote-method="remoteMethodToStore"
    remote
    clearable
    @change="serviceNameChange"
    :disabled="disabled"
  >
    <el-option
      v-for="item in serviceNameList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>
<script>
import { serviceStatList } from '@/api'
export default {
  props: {
    hval: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      serviceName: '',
      serviceNameList: []
    }
  },
  watch: {
    hval: {
      handler (val) {
        this.serviceName = val
      }
    }
  },
  mounted () {},
  methods: {
    // 服务站下拉列表
    getserviceNameList (name) {
      serviceStatList({ page: 1, size: 1500, name }).then(res => {
        this.serviceNameList = res.data.list
      })
    },
    // 远程搜索
    remoteMethodToStore (query) {
      this.getserviceNameList(query)
    },
    // 服务站选择
    serviceNameChange (val) {
      let item = {}
      if (val) {
        item = this.serviceNameList.find(item => item.id === val)
      } else {
        // 点击清空按钮
        item = {
          name: '',
          id: ''
        }
      }
      this.$emit('serviceNameChange', item)
    }
  }
}
</script>
