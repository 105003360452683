<template>
  <div style="margin-top:20px">
    <div class="search_flex">
      <div class="search_left">
        <salesArea
          :hval="salesArea"
          @change="salesAreaChange"
          @saleArea="saleArea"
        />
        <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
        <selectComp
          :hval="premiumProcessing"
          :data="premiumProcessingList"
          @change="premiumProcessingChange"
          placeholder="是否紧急"
        />
        <selectComp
          :hval="secendOrder"
          :data="secendOrderList"
          @change="secendOrderChange"
          placeholder="是否二次处理"
        />
        <handleUser
          placeholder="调度处理人"
          :hval="dispatchId"
          businessRole="dispatch"
          @change="dispatchIdChange"
        />
        <selectComp
          :hval="status"
          :data="sceneStatusList"
          @change="statusChange"
          placeholder="上门状态"
        />
        <selectComp
          :hval="arrivePrescription"
          :data="sceneAgingList"
          @change="arrivePrescriptionChange"
          placeholder="上门时效"
        />
        <selectComp
          v-if="myScene"
          :hval="type"
          :data="orderStatusList"
          @change="typeChange"
          placeholder="工单状态"
        />
        <areaData @change="areaChange" :hval="area" />
        <merchant :hval="storeName" @storeNameChange="storeNameChange" />
        <serviceStation
          :hval="serviceStationName"
          @serviceNameChange="serviceStationNameChange"
        />
        <!-- 业务类型、工单分类 -->
        <selectComp
          :data="orderTypeList"
          :hval="orderType"
          @change="orderTypeChange"
          placeholder="业务类型"
        />
        <selectComp
          :data="orderTypeChiList"
          :hval="orderTypeChi"
          @change="orderTypeChiChange"
          placeholder="工单类型(要先选业务类型)"
        />
        <selectComp
          :data="guestSentimentList"
          :hval="guestSentiment"
          @change="guestSentimentChange"
          placeholder="是否客情单"
        />
        <dateComp :hval="date" @change="dateChange" />
        <el-checkbox v-model="visit" style="margin-right:10px"
          >转回访</el-checkbox
        >
        <btnGroup @search="search" @reset="reset" />
        <btnComp @click="exportData" v-permission="'导出列表'"
          >导出列表</btnComp
        >
        <btnComp
          v-permission="'导出工单费用'"
          v-if="tabType === 'finish'"
          @click="orderExportDataToPay"
          >导出工单费用</btnComp
        >
      </div>
    </div>
    <tableComp
      :data="tableData"
      :col-configs="theadName"
      :total="total"
      :currentPage="curPage"
      :pageSize="pageSize"
      v-loading="tableLoading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column slot="statusName" label="上门状态" width="170">
        <template v-slot="scope">
          <div>{{ scope.row.statusName }}</div>
          <div v-if="scope.row.orderTypeName">
            [{{ scope.row.orderTypeName }}/{{ scope.row.orderTypeChiName }}]
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="repairContacter" label="报修人">
        <template v-slot="scope">
          <div>{{ scope.row.repairContacter }}</div>
          <img
            v-if="scope.row.customerLabel === 'VIP'"
            src="~@/assets/images/vip.png"
            alt=""
            width="20"
          />
        </template>
      </el-table-column>
      <el-table-column slot="code" label="工单编号" width="200">
        <template v-slot="scope">
          <div
            v-permission="'详情'"
            style="color:#385BFF;cursor:pointer"
            @click="codeClick(scope.row)"
          >
            {{ scope.row.code }}
            <div style="color:#FF7F00">{{ scope.row.orderRemark }}</div>
          </div>
          <div v-if="!flag">
            {{ scope.row.code }}
            <div style="color:#FF7F00">{{ scope.row.orderRemark }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="premiumProcessing" label="紧急度/二次处理/客情单">
        <template v-slot="scope">
          <span :style="{ color: scope.row.premiumProcessing ? 'red' : '' }">{{
            scope.row.premiumProcessing ? '急' : '-'
          }}</span>
          /
          <span :style="{ color: scope.row.firstOrder ? 'red' : '' }">{{
            scope.row.firstOrder ? '二次处理' : '-'
          }}</span>
          /
          <span :style="{ color: scope.row.guestSentiment ? 'red' : '' }">{{
            scope.row.guestSentiment ? '客情单' : '-'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="expressInfo" label="用户已收/待发(快递)">
        <template v-slot="scope">
          <span>{{
            scope.row.finishConsignment + '/' + scope.row.notConsignment
          }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="adress" label="上门地址" width="150">
        <template v-slot="scope">
          <span
            >{{ scope.row.provinceCityRegion
            }}{{ scope.row.detailAddress }}</span
          >
        </template>
      </el-table-column>
      <el-table-column slot="time" label="上门时效" width="200">
        <template v-slot="scope">
          <div>
            预约:{{
              scope.row.appointmentVisitTime
                ? scope.row.appointmentVisitTime
                : '-'
            }}
          </div>
          <div>
            到场:{{ scope.row.arrivalTime ? scope.row.arrivalTime : '-' }}
          </div>
          <div>
            <span
              :style="{ color: scope.row.arrivePrescription ? '' : '#385BFF' }"
              >{{ scope.row.arrivePrescription ? '已上门:' : '待上门:' }}</span
            >
            <span
              :style="{
                color: scope.row.arriveDuration <= 4 * 60 * 60 ? '' : 'red'
              }"
              >{{ scope.row.arriveDurationStr }}</span
            >
          </div>
          <div></div>
        </template>
      </el-table-column>
      <el-table-column slot="people" label="服务站">
        <template v-slot="scope">
          <span>{{
            (scope.row.serviceStationName
              ? scope.row.serviceStationName
              : '-') +
              '/' +
              (scope.row.visitName ? scope.row.visitName : '-')
          }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" fixed="right">
        <template v-slot="scope">
          <btnComp
            btnType="text"
            @click="accept(scope.row)"
            v-if="scope.row.status === 10"
            v-permission="'受理'"
            >受理</btnComp
          >
          <btnComp
            btnType="text"
            @click="toVisit(scope.row)"
            v-if="scope.row.visit"
            >转回访</btnComp
          >
          <btnComp
            v-permission="'二次售后'"
            btnType="text"
            @click="secondScenceBtn(scope.row)"
            >二次售后</btnComp
          >
        </template>
      </el-table-column>
    </tableComp>
    <!-- 详情弹窗 -->
    <drawerDetail @close="close" />
    <!-- 完成转回访弹窗  -->
    <toVisit
      :isShow="toVisitShow"
      :createdTime="orderCreateTime"
      :orderId="orderId"
      :diffTime="diffTime"
      @close="close"
    />
    <!-- 二次上门(借用订单系统中的新增售后弹窗)-->
    <secondScene
      :isShow="secondSceneShow"
      @close="close"
      :secondScenceOrderId="secondScenceOrderId"
      dialogType="secondScene"
    />
  </div>
</template>
<script>
import drawerDetail from '@/views/work-order/all-order/detail'
import toVisit from '@/views/work-order/all-order/dialog/toVisit'
import merchant from '@/views/components/merchant'
import salesArea from '@/views/components/salesArea'
import serviceStation from '@/views/components/serviceStation'
import areaData from '@/views/components/areaDataNew'
import handleUser from '@/views/components/handleUser'
import secondScene from '@/views/order-system/order-list/add'
import {
  sceneList,
  sceneExport,
  historyOrderPayExport,
  sceneUpdateStatus,
  completeReturnVisit
} from '@/api'
import { btnPermission } from '@/utils/directive'
import {
  sceneStatusList,
  orderStatusList,
  sceneAgingList,
  orderTypeList,
  orderTypeChiList1,
  orderTypeChiList2,
  orderTypeChiList3
} from '@/utils/constData'
import moment from 'moment'
import { mapMutations } from 'vuex'
export default {
  components: {
    toVisit,
    drawerDetail,
    merchant,
    areaData,
    handleUser,
    serviceStation,
    secondScene,
    salesArea
  },
  props: {
    // 是否加数据权限  我的上门传true
    dataAuthority: {
      type: Boolean,
      default: false
    },
    // 是否是我的上门
    myScene: {
      type: Boolean,
      default: false
    },
    // tabs类型
    tabType: {
      type: String,
      default: 'follow'
    }
  },
  data () {
    return {
      drawerShow: false,
      tableLoading: false,
      toVisitShow: false,
      secondSceneShow: false,
      orderCreateTime: '',
      diffTime: '',
      orderId: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      status: '',
      arrivePrescription: null,
      premiumProcessing: null,
      secendOrder: null,
      type: 1,
      code: '',
      startTime: '',
      endTime: '',
      storeId: '',
      storeName: '',
      serviceStationName: '',
      serviceStationId: '',
      dispatchId: '',
      date: [],
      level: '',
      cityId: '',
      salesArea: [],
      area: [],
      province: '',
      city: '',
      region: '',
      visit: false,
      guestSentiment: '',
      orderType: '',
      orderTypeChi: '',
      orderTypeChiList: [],
      tableData: [],
      secondScenceOrderId: '', // 点击二次上门获取的orderId
      premiumProcessingList: [
        { label: '正常', value: 0 },
        { label: '紧急', value: 1 }
      ],
      secendOrderList: [
        { label: '是', value: true },
        { label: '否', value: false }
      ],
      guestSentimentList: [
        { label: '是', value: true },
        { label: '否', value: false }
      ]
    }
  },
  computed: {
    orderStatusList () {
      return orderStatusList
    },
    sceneAgingList () {
      return sceneAgingList
    },
    orderTypeList () {
      return orderTypeList
    },
    orderTypeChiList1 () {
      return orderTypeChiList1
    },
    orderTypeChiList2 () {
      return orderTypeChiList2
    },
    orderTypeChiList3 () {
      return orderTypeChiList3
    },
    // 来判断是否有详情权限
    flag () {
      return btnPermission(this.$store.state.menuList, this.$route.path, '详情')
    },
    sceneStatusList () {
      // 根据不同的tabs上门状态下拉不同
      if (this.tabType === 'follow') {
        return sceneStatusList.slice(0, -3)
      } else {
        return sceneStatusList.slice(-3)
      }
    },
    // 建单与当前时间相差大于72个小时 才出现弹窗
    diffSeconds () {
      const date1 = moment(this.orderCreateTime)
      const date2 = moment(new Date())
      const date3 = date2.diff(date1, 'seconds') // 计算相差的秒数
      return date3
    },
    theadName () {
      return this.myScene
        ? [
          { slot: 'expressInfo' },
          { slot: 'statusName' },
          // { prop: 'statusName', label: '上门状态', width: 100 },
          { prop: 'orderStatusName', label: '工单状态', width: 100 },
          { slot: 'premiumProcessing' },
          { slot: 'code' },
          { prop: 'storeName', label: '商家' },
          { slot: 'repairContacter' },
          { slot: 'adress' },
          { slot: 'people' },
          { prop: 'price', label: '上门成本' },
          { slot: 'time' },
          { slot: 'operate' }
        ]
        : [
          { slot: 'expressInfo' },
          { slot: 'statusName' },
          // { prop: 'statusName', label: '上门状态', width: 100 },
          { slot: 'premiumProcessing' },
          { slot: 'code' },
          { prop: 'storeName', label: '商家' },
          { slot: 'repairContacter' },
          { slot: 'adress' },
          { prop: 'dispatchName', label: '调度' },
          { slot: 'people' },
          { prop: 'price', label: '上门成本' },
          { slot: 'time' },
          { slot: 'operate' }
        ]
    }
  },
  mounted () {
    // this.getList()
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.initSalesArea = salesArea
      this.initLevel = level
      this.initCityId = cityId
      this.level = level
      this.cityId = cityId
      this.salesArea = salesArea
      this.getList()
    },
    getList () {
      this.params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        status: this.status,
        arrivePrescription: this.arrivePrescription,
        type: this.myScene ? this.type : null,
        code: this.code,
        startTime: this.startTime,
        endTime: this.endTime,
        visit: this.visit,
        premiumProcessing: this.premiumProcessing,
        secendOrder: this.secendOrder,
        storeId: this.storeId,
        serviceStationId: this.serviceStationId,
        dispatchId: this.dispatchId,
        province: this.province,
        city: this.city,
        region: this.region,
        dataAuthority: this.dataAuthority,
        category: this.tabType === 'follow' ? 1 : 2,
        level: this.level,
        cityId: this.cityId,
        orderType: this.orderType,
        orderTypeChi: this.orderTypeChi,
        guestSentiment: this.guestSentiment
      }
      this.tableLoading = true
      sceneList(this.params).then(res => {
        this.tableLoading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    // 点击工单号弹窗
    codeClick (info) {
      this.drawerShow = true
      const { orderId, categoryId, orderCode } = info
      this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id: orderId, categoryId, orderCode })
    },
    // 导出
    exportData () {
      sceneExport(this.params)
      this.$message({
        message:
          '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    orderExportDataToPay () {
      historyOrderPayExport(this.params)
      this.$message({
        message:
          '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    salesAreaChange (salesArea, level, cityId) {
      this.salesArea = salesArea
      this.level = level
      this.cityId = cityId
    },
    areaChange (val) {
      const [a, b, c] = val
      this.area = val
      this.province = a || ''
      this.city = b || ''
      this.region = c || ''
    },
    dispatchIdChange (val) {
      this.dispatchId = val
    },
    premiumProcessingChange (val) {
      this.premiumProcessing = val
    },
    secendOrderChange (val) {
      this.secendOrder = val
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    serviceStationNameChange (val) {
      this.serviceStationId = val.id
      this.serviceStationName = val.name
    },
    statusChange (val) {
      this.status = val
    },
    arrivePrescriptionChange (val) {
      this.arrivePrescription = val
    },
    typeChange (val) {
      this.type = val
    },
    codeBlur (val) {
      this.code = val
    },
    orderTypeChange (val) {
      this.orderType = val
      this.orderTypeChi = ''
      if (val) {
        this.orderTypeChiList = this['orderTypeChiList' + val]
      } else {
        this.orderTypeChiList = []
      }
    },
    orderTypeChiChange (val) {
      this.orderTypeChi = val
    },
    guestSentimentChange (val) {
      this.guestSentiment = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    // 受理
    accept (row) {
      sceneUpdateStatus({ id: row.id, status: 20 }).then(res => {
        this.$message.success('受理成功')
        this.getList()
      })
    },
    // 转回访
    toVisit (row) {
      this.orderCreateTime = row.orderCreateTime
      this.orderId = row.orderId
      // 建单与当前时间相差大于72个小时 才出现弹窗
      if (this.diffSeconds > 72 * 3600) {
        const time = moment.duration(this.diffSeconds, 'seconds')
        const days = time.days()
        const hours = time.hours()
        const minutes = time.minutes()
        const seconds = time.seconds()
        this.diffTime = `${days}天${hours}时${minutes}分${seconds}秒`
        this.toVisitShow = true
        return
      }
      const params = {
        id: this.orderId
      }
      completeReturnVisit(params).then(res => {
        this.$message.success('完成转客服回访成功')
        this.getList()
      })
    },
    // 二次上门
    secondScenceBtn ({ orderId }) {
      this.secondScenceOrderId = orderId
      this.secondSceneShow = true
    },
    close () {
      this.toVisitShow = false
      this.drawerShow = false
      this.secondSceneShow = false
      this.SET_DRAWER_SHOW(false)
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.status = ''
      this.arrivePrescription = null
      this.type = this.myScene ? 1 : null
      this.code = ''
      this.startTime = ''
      this.premiumProcessing = null
      this.secendOrder = null
      this.storeId = ''
      this.storeName = ''
      this.serviceStationId = ''
      this.serviceStationName = ''
      this.endTime = ''
      this.date = []
      this.area = []
      this.province = ''
      this.city = ''
      this.region = ''
      this.dispatchId = ''
      this.visit = false
      this.orderTypeChiList = []
      this.orderType = ''
      this.orderTypeChi = ''
      this.guestSentiment = ''
      // 重置后赋值初始值
      this.level = this.initLevel
      this.cityId = this.initCityId
      this.salesArea = this.initSalesArea
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.search_left {
  flex-wrap: wrap;
  > div {
    margin-bottom: 10px;
  }
  .el-button {
    margin-bottom: 10px;
  }
}
</style>
